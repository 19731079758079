import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { useAppDispatch, useAppSelector, AppActions } from 'src/store';
import StateList from './stateList';
import { getState } from 'src/lib/geolocation';

const ButtonHref = "/us/programs";
const { selectState } = AppActions;
const STATE_SELECT_PLACEHOLDER = "Select Your State";
const GEOLOCATION_STATE_FALLBACK = "California";

const StatePicker = () => {
    const dispatch = useAppDispatch();
    const selectedState = useAppSelector(state => state.selectedState.selected) || "";
    const [ statePickerState, setStatePickerState ] = useState(selectedState || STATE_SELECT_PLACEHOLDER);

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newState = (e.target.value === STATE_SELECT_PLACEHOLDER) ? "" : e.target.value;
        setStatePickerState(newState);
    }

    const handleSelectButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        const newState = (selectedState === STATE_SELECT_PLACEHOLDER) ? "" : statePickerState;
        dispatch(selectState(newState));
        setStatePickerState(newState);
    }

    useEffect(() => {
        getState().then((state) => {
            const newState = state || GEOLOCATION_STATE_FALLBACK
            dispatch(selectState(newState));
            setStatePickerState(newState)
        });
    }, []);

    // react devs are really annoying sometimes
    // https://github.com/facebook/create-react-app/issues/2070
    // suppresses react complaining about controlled/uncontrolled forms in dev console
    // you cannot start with disabled options selected in forms managed by react
        const ignored = 'Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>.';
        const temp = console.error;
        console.error = (msg: string | null | undefined, ...args) => {
            if (!!msg && !msg.match(ignored)) {
                temp.apply(console, args);
              }
        }

    let selected = false;
    return <div className="flex flex-col md:flex-row justify-between rounded-md p-4 shadow-xl bg-white">
        <div className="flex flex-col w-full text-navy md:mr-4 mb-4 md:mb-0 before:content-[url('../images/usx_down_arrow.svg')] before:absolute before:-top-[2px] before:left-[160px] relative ">
            <label htmlFor="stateSelect" className="font-medium font-sans">Select Your State</label>
            <select id="stateSelect" onChange={handleSelect} className="bg-transparent absolute outline-0 pb-9 z-10 appearance-none text-base w-full opacity-0" data-test="statepicker">
                {StateList.map(state => {
                    if (statePickerState == state) {
                        selected = true;
                    } else {
                        selected = false;
                    }
                    return <option key={`option-${state}`} selected={selected}>{state || STATE_SELECT_PLACEHOLDER}</option>
                })}
            </select>
            <div className={`text-2xl text-navy opacity-50 font-semibold leading-7 pt-1 font-sans`} data-test="statepicker-value">{statePickerState || "\u00A0"}</div>
        </div>
        <Link to={ButtonHref}><button className="btn btn-primary px-4 py-4 min-w-[180px] w-full md:w-auto m" data-test="statepicker-btn" onClick={handleSelectButton}>Find Your Program</button></Link>
    </div>;
};

export default StatePicker;
